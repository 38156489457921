import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../url";

export const userBlogSlice = createApi({
  reducerPath: "userBlog",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Blog"],
  endpoints: (build) => ({
    retrieveUserSingleBlogPost: build.query({
      query: ({ slug }) => ({
        url: `/user/blog/single/${slug}`,
        method: "GET",
      }),
      providesTags: ["Retrieve"],
    }),
    getAllUserBlogPost: build.query({
      query: ({ page = 1, limit = 12, ...otherParams }) => ({
        url: `/user/blog/list?${new URLSearchParams({
          page,
          limit,
          ...otherParams,
        }).toString()}`,
        method: "GET",
      }),
      providesTags: ["AllBlog"],
    }),
    getRelatedBlogPosts: build.mutation({
      query: ({ title }) => ({
        url: `/user/blog/related`,
        method: "POST",
        data: { title },
      }),
    }),
  }),
});

export const {
  useLazyRetrieveUserSingleBlogPostQuery,
  useGetAllUserBlogPostQuery,
  useGetRelatedBlogPostsMutation,
} = userBlogSlice;
