import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import loadable from "@loadable/component";
import axios from "axios";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Provider } from "react-redux";
import { store } from "./store";

const Home = loadable(() => import("./pages/Home"));
const Blog = loadable(() => import("./pages/Blog"));
const Contact = loadable(() => import("./pages/Contact"));
const CaseStudy = loadable(() => import("./pages/CaseStudy"));
const PostCmp = loadable(() => import("./pages/Post"));
const AboutUs = loadable(() => import("./pages/AboutUs"));
const AiToolsPage = loadable(() => import("./pages/AiTools"));
const DeepResearch = loadable(() => import("./pages/AiTools/DeepResearch"));
const SmartSearch = loadable(() => import("./pages/AiTools/SmartSearch"));

const AdminSignin = loadable(() => import("./pages/Admin/Auth/Signin"));
const DashboardLayout = loadable(() => import("./components/DashboardLayout"));
const BlogList = loadable(() => import("./pages/Admin/Dashboard/Blog"));
const CreateBlogPost = loadable(() =>
  import("./pages/Admin/Dashboard/Blog/CreateBlogPost")
);
const SingleBlogPost = loadable(() =>
  import("./pages/Admin/Dashboard/Blog/SingleBlogPost")
);

function App() {
  useEffect(() => {
    const getCookieValue = (name) => {
      const cookieName = `${name}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
      return "";
    };

    const fbcValue = getCookieValue("_fbc");

    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return ""; // Return empty string in case of error
      }
    };

    const fetchData = async () => {
      const ipAddress = await fetchIpAddress();
      const userAgent = navigator.userAgent;

      axios.get(
        `https://capi.bles-software.com?event=page_view&pid=936189111057967&ip=${ipAddress}&ua=${userAgent}&accessTokens=EAATkZAMb9LmoBOZC8dxSqZAp1T4Blv2gmfVJp0IZB4vm2Qgp0Jnh6zudv5VBaMzWZAOMMwrp3Y07YjkFLlRVQUPI0ZCcZBrSUbVoPmBDWV9KoiwAyT4hEbK26pWGpyJBzMKFRvUYmhsRopfcyEgFJOZAtQaOBxX6bzq5KSwRybOjIva0ShccIvAhZB2o7oRglrqCxfAZDZD&fbc=${fbcValue}`
      );
    };

    fetchData();
  }, []);

  function ScrollToTopOnMount() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTopOnMount />
        <Helmet>
          {/* Hotjar Tracking Code for https://ai-gig.bles-software.com  */}
          <script>
            {`
 (function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:3886396,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;r.defer=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </script>
          <meta
            name="description"
            content={"We build hands-off growth systems for B2B founders"}
          />
        </Helmet>
        <Routes>
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<PostCmp />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/case-study/:slug" element={<CaseStudy />} />
          <Route path="/ai-tools-lab" element={<AiToolsPage />} />
          <Route
            path="/ai-tools-lab/deep-research/:id"
            element={<DeepResearch />}
          />
          <Route path="/ai-tools-lab/smart-search" element={<SmartSearch />} />

          <Route path="/admin/signin" element={<AdminSignin />} />
          <Route path="/admin/dashboard" element={<DashboardLayout />}>
            <Route path="blog" element={<BlogList />} />
            <Route path="blog/create" element={<CreateBlogPost />} />
            <Route
              path="blog/single/:blogPostId"
              element={<SingleBlogPost />}
            />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
